<template>
  <div class="flex-grow-1 d-flex align-items-center justify-content-center">
    <div class="login-container" v-if="!sended">
      <h5 class="text-gray-100 text-center mb-3">Recovery password</h5>
      <b-form class="mb-2 invalid" @submit.prevent="send()">
        <tp-icon-input icon="person" v-model="email" required class="mb-3" type="email" placeholder="Email" />

        <b-btn :disabled="loading" class="w-100 rounded-pill button-gradient-primary border-0 scale-drawdown-animation mb-3" type="submit">
          <span v-if="loading">
            <b-spinner style="height: 16px; width: 16px;"  label="Spinning" />
            Loading
          </span>

          <span v-if="!loading">Send</span>
        </b-btn>

        <b-link class="link-gray-300" to="/login">
          <b-icon icon="chevron-left" /> Go to login
        </b-link>
      </b-form>
    </div>

    <div v-else class="d-flex flex-column align-items-center">
      <img src="@/assets/illustrations/success_illustration.svg" width="400" class="mb-5" alt="">

      <p class="h4">
        {{ message.msg }}
      </p>

      <p v-if="sended">
        If you don't see the email, please check your "Spam" folder.
      </p>

      <router-link class="btn button-gradient-primary" to="/login">
        Go to login
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "recovery_password",

  data: () => ({
    email: '',
    loading: false,
    sended: false,
    message: '',
  }),

  methods: {
    send() {
      this.axios.post(`password-recovery/${this.email}`)
          .then(response => {
            this.sended = true
            this.message = response.data
          })
    },
  },
}
</script>

<style scoped>

</style>